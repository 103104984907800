import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";
import { createBoot, getBootData } from "../../services/bootservice";
import Layout from "../../components/Layout/Layout";
import "./createRobot.scss";

const TreeBuilder = () => {
  const [botData, setBotData] = useState({
    name: "",
    user_id: 2,
    whatsapp_number: "",
    keyword: "start",
    custom_navigation_message: "",
    tree: {},
    status: ""
  });

  const addInitialNode = (type) => {
    const updatedTree = { ...botData.tree };
    
    if (type === "buttons") {
      updatedTree.root = {
        type: "buttons",
        buttons: []
      };
    } else if (type === "list") {
      updatedTree.root = {
        type: "list",
        sections: [{
          name: "",
          options: []
        }]
      };
    } else if (type === "message") {
      updatedTree.root = {
        type: "result",
        messages: [""]
      };
    }
    
    setBotData(prev => ({ ...prev, tree: updatedTree }));
  };

  const addOption = (nodeId, sectionIndex) => {
    const updatedTree = { ...botData.tree };
    const newOptionId = `result${Object.keys(updatedTree).length + 1}`;
    
    updatedTree[nodeId].sections[sectionIndex].options.push({
      name: "",
      next: newOptionId
    });

    updatedTree[newOptionId] = {
      type: "result",
      messages: [""]
    };

    setBotData(prev => ({ ...prev, tree: updatedTree }));
  };

  const addButton = (nodeId) => {
    const updatedTree = { ...botData.tree };
    const newSectionId = `section${Object.keys(updatedTree).length + 1}`;
    
    updatedTree[nodeId].buttons.push({
      name: "",
      next: newSectionId
    });

    updatedTree[newSectionId] = {
      type: "list",
      sections: [{
        name: "",
        options: []
      }]
    };

    setBotData(prev => ({ ...prev, tree: updatedTree }));
  };

  const renderInitialTypeSelector = () => (
    <div className="initial-type-selector text-center p-4">
      <h4>اختر نوع العقدة الرئيسية</h4>
      <div className="d-flex gap-2 justify-content-center">
        <button 
          className="btn btn-outline-primary"
          onClick={() => addInitialNode("buttons")}
        >
          أزرار
        </button>
        <button 
          className="btn btn-outline-primary"
          onClick={() => addInitialNode("list")}
        >
          قائمة
        </button>
        <button 
          className="btn btn-outline-primary"
          onClick={() => addInitialNode("message")}
        >
          رسالة
        </button>
      </div>
    </div>
  );

  const renderNode = (nodeId, depth = 0) => {
    if (depth > 10) return null;
    
    const node = botData.tree[nodeId];
    if (!node) return null;

    return (
      <div className="node-container" key={nodeId}>
        <div className="node-content">
          <h4>{nodeId === "root" ? "القائمة الرئيسية" : nodeId}</h4>

          {node.type === "buttons" && (
            <div className="buttons-section">
              {node.buttons.map((button, index) => (
                <div key={index} className="button-item p-3 bg-light mb-2">
                  <div className="d-flex justify-content-between mb-2">
                    <span>زر</span>
                    <button 
                      className="btn btn-danger btn-sm"
                      onClick={() => {
                        const updatedTree = { ...botData.tree };
                        updatedTree[nodeId].buttons.splice(index, 1);
                        setBotData(prev => ({ ...prev, tree: updatedTree }));
                      }}
                    >
                      ×
                    </button>
                  </div>
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="نص الزر"
                    value={button.name}
                    onChange={(e) => {
                      const updatedTree = { ...botData.tree };
                      updatedTree[nodeId].buttons[index].name = e.target.value;
                      setBotData(prev => ({ ...prev, tree: updatedTree }));
                    }}
                  />
                  {button.next && depth < 10 && renderNode(button.next, depth + 1)}
                </div>
              ))}
              <button 
                className="btn btn-primary w-100"
                onClick={() => addButton(nodeId)}
              >
                إضافة زر
              </button>
            </div>
          )}

          {node.type === "list" && (
            <div className="list-section">
              {node.sections.map((section, sectionIndex) => (
                <div key={sectionIndex} className="section-item p-3 bg-light mb-2">
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="عنوان القسم"
                    value={section.name}
                    onChange={(e) => {
                      const updatedTree = { ...botData.tree };
                      updatedTree[nodeId].sections[sectionIndex].name = e.target.value;
                      setBotData(prev => ({ ...prev, tree: updatedTree }));
                    }}
                  />
                  {section.options.map((option, optionIndex) => (
                    <div key={optionIndex} className="option-item p-3 mb-2">
                      <div className="d-flex justify-content-between mb-2">
                        <span>خيار</span>
                        <button 
                          className="btn btn-danger btn-sm"
                          onClick={() => {
                            const updatedTree = { ...botData.tree };
                            updatedTree[nodeId].sections[sectionIndex].options.splice(optionIndex, 1);
                            setBotData(prev => ({ ...prev, tree: updatedTree }));
                          }}
                        >
                          ×
                        </button>
                      </div>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="نص الخيار"
                        value={option.name}
                        onChange={(e) => {
                          const updatedTree = { ...botData.tree };
                          updatedTree[nodeId].sections[sectionIndex].options[optionIndex].name = e.target.value;
                          setBotData(prev => ({ ...prev, tree: updatedTree }));
                        }}
                      />
                      {option.next && depth < 10 && renderNode(option.next, depth + 1)}
                    </div>
                  ))}
                  <button 
                    className="btn btn-primary w-100"
                    onClick={() => addOption(nodeId, sectionIndex)}
                  >
                    إضافة خيار
                  </button>
                </div>
              ))}
            </div>
          )}

          {node.type === "result" && (
            <div className="result-section">
              {node.messages.map((message, index) => (
                <div key={index} className="mb-2">
                  <textarea
                    className="form-control"
                    placeholder="نص الرسالة"
                    value={message}
                    onChange={(e) => {
                      const updatedTree = { ...botData.tree };
                      updatedTree[nodeId].messages[index] = e.target.value;
                      setBotData(prev => ({ ...prev, tree: updatedTree }));
                    }}
                  />
                </div>
              ))}
              <button 
                className="btn btn-primary w-100"
                onClick={() => {
                  const updatedTree = { ...botData.tree };
                  updatedTree[nodeId].messages.push("");
                  setBotData(prev => ({ ...prev, tree: updatedTree }));
                }}
              >
                إضافة رسالة
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleInputChange = (field, value) => {
    setBotData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const renderBotInfo = () => (
    <div className="bot-info mb-4">
      <h3 className="form-header">بيانات الروبوت الذكي</h3>
      <div className="form-layout">
        <div className="mb-3">
          <label>اسم الروبوت</label>
          <input
            type="text"
            className="form-control"
            value={botData.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>رقم الواتساب</label>
          <input
            type="text"
            className="form-control"
            value={botData.whatsapp_number}
            onChange={(e) => handleInputChange("whatsapp_number", e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>الكلمة المفتاحية للتشغيل</label>
          <input
            type="text"
            className="form-control"
            value={botData.keyword}
            onChange={(e) => handleInputChange("keyword", e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>رسال نقل مخصصة (اختياري)</label>
          <textarea
            className="form-control"
            value={botData.custom_navigation_message}
            onChange={(e) => handleInputChange("custom_navigation_message", e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>حالة الروبوت</label>
          <select
            className="form-control"
            value={botData.status}
            onChange={(e) => handleInputChange("status", e.target.value)}
          >
            <option value="off">معطل</option>
            <option value="on">مفعل</option>
          </select>
        </div>
      </div>
    </div>
  );

  const handleSubmit = async () => {
    try {
      // Validation
      if (!botData.name) {
        return toast.error("الرجاء إدخال اسم الروبوت");
      }
      if (!botData.whatsapp_number) {
        return toast.error("الرجاء إدخال رقم الواتساب");
      }
      if (Object.keys(botData.tree).length === 0) {
        return toast.error("الرجاء إنشاء شجرة الردود");
      }

      // Submit data
      const response = await createBoot(botData);
      if (response) {
        toast.success("تم حفظ الروبوت بنجاح");
      }
    } catch (error) {
      toast.error("حدث خطأ أثناء حفظ الروبوت");
      console.error(error);
    }
  };

  return (
    <Layout>
      <div className="container mt-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h1>تسجيل ردود</h1>
          <button 
            className="btn btn-success"
            onClick={handleSubmit}
          >
            حفظ
          </button>
        </div>
        
        {renderBotInfo()}
        
        <div className="bot-tree">
          {Object.keys(botData.tree).length === 0 ? 
            renderInitialTypeSelector() : 
            renderNode("root")
          }
        </div>
      </div>
    </Layout>
  );
};

export default TreeBuilder;

