import axios from "axios";
import Layout from "../../components/Layout/Layout";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchAccountsAsync,
  selectAccounts,
  selectAccountStatus,
  selectAccountError,
} from "../../store/reducers/accountSlice";
import { getUserData } from "../../services/profileService";
import { changeStatus, createAutoReply, fetchAutoReplies } from "../../services/autoReplayService";
import { toast } from "react-toastify";

const AutoReplyForm = () => {
  const [userId, setUserId] = useState(null);
  const [runEffect,setRunEffect] = useState(0); 
  const [formData, setFormData] = useState({



    include_ai_map:"1",
    custom_message:"",
    status: "off", // Default active state
    number: "",
    messages: "",
  });
  const [autoReplies, setAutoReplies] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accounts = useSelector(selectAccounts);
  const accountStatus = useSelector(selectAccountStatus);
  const accountError = useSelector(selectAccountError);

  useEffect(() => {
    dispatch(fetchAccountsAsync());
  }, [dispatch]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const userData = await getUserData();
        setUserId(userData.data.id);
        let res=await fetchAutoReplies();
        setAutoReplies(res);
      } catch (err) {
        setError("Failed to fetch user data");
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [runEffect]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    try {
     let res= await createAutoReply( formData);
     console.log(res);
     setLoading(false);
     setRunEffect((...prev)=>prev+1);
     toast.success("تم إضافة الرد الآلي بنجاح");
    } catch (error) {
console.log(error);
    }
   
  };

  const handleEdit = (rule) => {
    setEditingId(rule.id);
    setFormData({
      phone_sender: rule.phone_sender,
      keywords: rule.keywords.join(", "),
      response: rule.response,
      matchType: rule.matchType,
      mediaUrl: rule.mediaUrl || "",
      isActive: rule.isActive,
      number: rule.phone_sender,
      messages: rule.response,
    });
  };

  const handleDelete = async (ruleId) => {
  
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        await axios.delete(` https://whats.wolfchat.online/public/api/auto-replies/delete/${ruleId}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        await fetchAutoReplies(userId);
        setRunEffect((...prev)=>prev+1);
        toast.success("تم حذف الرد الآلي بنجاح");
      } catch (err) {
        setError("Failed to delete auto-reply rule");
        console.error("Error deleting rule:", err);
      } finally {
        setLoading(false);
      }
    
  };

    const handleChangeStatus = async (ruleId) => {
      try {
        let res = await changeStatus(ruleId);
        console.log(res);
        setRunEffect((...prev)=>prev+1);
        toast.success("تم تعطيل الرد الآلي بنجاح");
      } catch (error) {
        console.log(error);
      }
    } 
  const resetForm = () => {
    setFormData({
      include_ai_map:"1",
      custom_message:"",
      status: "off", // Default active state
      number: "",
      messages: "",
    });
    setEditingId(null);
  };

  if (loading && !autoReplies.length) {
    return (
      <Layout>
        <div className="container mt-4">
          <div className="text-center">جاري التحميل...</div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-12">
            <h2>الرد الآلي</h2>
            <p>
              قم بإعداد الردود الآلية على الرسائل الواردة باستخدام الكلمات
              المفتاحية
            </p>
            {error && <div className="alert alert-danger">{error}</div>}
          </div>
        </div>

        <form className="mt-4" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-section">
                <h3>{editingId ? "تعديل الرد الآلي" : "إضافة رد آلي جديد"}</h3>

                <div className="mb-3 p-3">
                  <label htmlFor="phone_sender" className="form-label">
                    حساب الواتساب
                  </label>
                  <select
                    id="phone_sender"
                    name="number"
                    className="form-control"
                    value={formData.number}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      اختر...
                    </option>
                    {accounts?.numbers?.map((account) => (
                      <option key={account.id} value={account.phone_number}>
                        {account.name || account.phone_number}
                      </option>
                    ))}
                  </select>
                </div>

             

                <div className="mb-3 p-3">
                  <label htmlFor="keywords" className="form-label">
                    الكلمات المفتاحية (مفصولة بفواصل)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="keywords"
                    name="keywords"
                    value={formData.keywords}
                    onChange={handleInputChange}
                    placeholder="مثال: مرحبا, السعر, المنتج"
                    required
                  />
                </div>

                <div className="mb-3 p-3">
                  <label htmlFor="messages" className="form-label">
                    الرد الآلي
                  </label>
                  <textarea
                    className="form-control"
                    id="messages"
                    name="messages"
                    value={formData.messages}
                    onChange={handleInputChange}
                    rows="4"
                    required
                  />
                </div>

              

                <div className="mb-3 p-3">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="status"
                      name="status" 
                      checked={formData.status === "on"}
                      onChange={(e) =>
                        handleInputChange({
                          target: {
                            name: "status",
                            value: e.target.checked ? "on" : "off",
                          },
                        })
                      }
                    />
                    <label className="form-check-label " htmlFor="status">
                      تفعيل الرد الآلي
                    </label>
                  </div>
                </div>

                <div className="mb-3 p-3">
                  <button
                    type="submit"
                    className="btn btn-primary me-2"
                    disabled={loading}
                  >
                    اضافه الرد الألي
                  </button>
                  {editingId && (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={resetForm}
                    >
                      إلغاء التعديل
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-section">
                <h3>الردود الآلية الحالية</h3>
                <div className="table-responsive" style={{maxHeight: "100vh", overflowY: "auto"}}>
                  <table className="table">
                    <thead>
                      <tr>
                        
                        <th>نوع المطابقة</th>
                        <th>الرد</th>
                        <th>الحالة</th>
                        <th>الإجراءات</th>
                      </tr>
                    </thead>
                    <tbody>
                      {autoReplies.map((rule) => (
                        <tr key={rule.id}>
                          
                          <td>
                            {rule.matchType === "exact"
                              ? "مطابقة تامة"
                              : rule.matchType === "contains"
                              ? "يحتوي على"
                              : "تعبير منتظم"}
                          </td>
                          <td>{rule.messages}</td>
                          <td>
                            <span
                              className={`badge ${
                                rule.status === "on" ? "bg-success" : "bg-secondary"
                              }`}
                            >
                              {rule.status === "on" ? "مفعل" : "معطل"}
                            </span>
                          </td>
                          <td>
                            <button
                              className="btn btn-sm btn-primary me-2"
                              onClick={() =>handleChangeStatus(rule.id)}
                            >
                              {rule.status === "on" ? "تعطيل" : "تفعيل"}
                            </button>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => handleDelete(rule.id)}
                            >
                              حذف
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AutoReplyForm;
